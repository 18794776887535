"use strict";
// Define the color map for DPE letters
Object.defineProperty(exports, "__esModule", { value: true });
exports.dpeClasses = void 0;
exports.getDpeColor = getDpeColor;
// based on color code used at https://data.ademe.fr/applications/dpe-v2-logements-existants-classes-energetiques
// this is likely bright colors since they are displayed on a map
var dpeColorMap = {
    'A': '#2C8814', // rgb(44, 136, 20)
    'B': '#3CC329', // rgb(60, 195, 41)
    'C': '#5DE040', // rgb(93, 224, 64)
    'D': '#F7EB0B', // rgb(247, 235, 11)
    'E': '#F3C064', // rgb(243, 192, 100)
    'F': '#F79408', // rgb(247, 148, 8)
    'G': '#EC1717', // rgb(236, 23, 23)
};
exports.dpeClasses = ['A', 'B', 'C', 'D', 'E', 'F', 'G'];
// Function to get the color based on DPE letter
function getDpeColor(dpeLetter) {
    return dpeColorMap[dpeLetter.toUpperCase()] || '#FFFFFF'; // Default to white if letter is invalid
}

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculateValidatedBuildings = exports.calculateTotalSurface = exports.calculateAdjustedEstimation = exports.getConstructionHeight = exports.getSurfaceWithoutWallsThickness = exports.ROOF_TYPE_REVERSE_MAP = exports.ROOF_TYPE_MAP = exports.ATTIC_STANDARDS = exports.ATTIC_THRESHOLD = exports.FLOOR_THRESHOLD = exports.SURFACE_THRESHOLD = void 0;
var formatters_1 = require("../../../utils/formatters");
exports.SURFACE_THRESHOLD = 20;
exports.FLOOR_THRESHOLD = 3; // 3 meters per floor
exports.ATTIC_THRESHOLD = 2.5; // 2.5 meters for attic
exports.ATTIC_STANDARDS = {
    '30': { atticSurface: 37.6, perimeter: 40 },
    '50': { atticSurface: 69.8, perimeter: 40 },
};
exports.ROOF_TYPE_MAP = {
    'ne_pas_utiliser': null,
    'ardoise': 1,
    'tuile': 2,
    'autre': 3,
};
exports.ROOF_TYPE_REVERSE_MAP = {
    1: 'ardoise',
    2: 'tuile',
    3: 'autre',
};
var getSurfaceWithoutWallsThickness = function (building) {
    var emprise = parseFloat(building.emprise) || 0;
    var perimetre = parseFloat(building.perimetre) || 0;
    return emprise - perimetre * (30 / 100);
};
exports.getSurfaceWithoutWallsThickness = getSurfaceWithoutWallsThickness;
var getConstructionHeight = function (building) {
    var heightFactor = 'Default';
    var constructionHeight = 0;
    if (building.altitudeMaximaleToit != null && building.altitudeMaximaleSol != null) {
        heightFactor = 'Faitage';
        constructionHeight = (parseFloat(building.altitudeMaximaleToit) || 0) - (parseFloat(building.altitudeMaximaleSol) || 0);
    }
    else if (building.altitudeMinimaleToit != null && building.altitudeMaximaleSol != null) {
        heightFactor = 'Gouttière';
        constructionHeight = (parseFloat(building.altitudeMinimaleToit) || 0) - (parseFloat(building.altitudeMaximaleSol) || 0);
    }
    else if (building.hauteur != null) {
        heightFactor = 'Déclaration';
        constructionHeight = parseFloat(building.hauteur) || 0;
    }
    return { Facteur: heightFactor, Hauteur: constructionHeight };
};
exports.getConstructionHeight = getConstructionHeight;
var calculateAdjustedEstimation = function (building, floorCountValue, roofTypeValue) {
    if (parseFloat(building.emprise) <= exports.SURFACE_THRESHOLD) {
        return {
            habitableHorsCombles: 0,
            nbNiveaux: 0,
            habitableComblesMin: 0,
            habitableComblesMax: 0,
            habitableComblesMoy: 0,
            totalHabitableMin: 0,
            totalHabitableMax: 0,
            totalHabitableAvg: 0,
        };
    }
    var surfaceWithoutWallsThickness = (0, exports.getSurfaceWithoutWallsThickness)(building);
    var heightDetails = (0, exports.getConstructionHeight)(building);
    var height = heightDetails.Hauteur;
    var heightFactor = heightDetails.Facteur;
    var perimetre = parseFloat(building.perimetre) || 0;
    var floorsCount = Math.max(Math.floor(height / exports.FLOOR_THRESHOLD), 1);
    var nombreDetages = parseInt(floorCountValue) || parseInt(building.nombreDEtages);
    if (nombreDetages && nombreDetages > 0) {
        floorsCount = nombreDetages;
    }
    var remainingHeight = height - floorsCount * exports.FLOOR_THRESHOLD;
    var isLastLevelUnderRoof = 0;
    if ((heightFactor === 'Faitage' && remainingHeight < exports.ATTIC_THRESHOLD && floorsCount > 1) || roofTypeValue === 'tuile') {
        isLastLevelUnderRoof = 1;
    }
    else if (remainingHeight >= exports.ATTIC_THRESHOLD) {
        // TODO : discuss this pseudo fix since it is just for user experience but not accurate
        floorsCount += 1;
    }
    var surfaceWithoutLastLevel = surfaceWithoutWallsThickness * (floorsCount - isLastLevelUnderRoof);
    var atticMinSurface, atticMaxSurface, atticAvgSurface;
    if (floorsCount < 6) {
        atticMinSurface = perimetre * exports.ATTIC_STANDARDS['30'].atticSurface / exports.ATTIC_STANDARDS['30'].perimeter;
        atticMaxSurface = perimetre * exports.ATTIC_STANDARDS['50'].atticSurface / exports.ATTIC_STANDARDS['50'].perimeter;
        atticAvgSurface = (atticMinSurface + atticMaxSurface) / 2;
    }
    else {
        atticMinSurface = atticMaxSurface = atticAvgSurface = surfaceWithoutWallsThickness;
    }
    var totalHabitableMin = surfaceWithoutLastLevel + atticMinSurface;
    var totalHabitableMax = surfaceWithoutLastLevel + atticMaxSurface;
    var totalHabitableAvg = surfaceWithoutLastLevel + atticAvgSurface;
    return {
        surfaceWithoutWallsThickness: surfaceWithoutWallsThickness,
        heightFactor: heightFactor,
        height: height,
        habitableHorsCombles: surfaceWithoutLastLevel,
        nbNiveaux: floorsCount,
        habitableComblesMin: atticMinSurface,
        habitableComblesMax: atticMaxSurface,
        habitableComblesMoy: atticAvgSurface,
        totalHabitableMin: totalHabitableMin,
        totalHabitableMax: totalHabitableMax,
        totalHabitableAvg: totalHabitableAvg,
    };
};
exports.calculateAdjustedEstimation = calculateAdjustedEstimation;
var calculateTotalSurface = function (copro, returnNumber) {
    if (returnNumber === void 0) { returnNumber = false; }
    var validatedSurface = copro.batiments
        .filter(function (building) { return building.isValidatedByUser === true; })
        .reduce(function (sum, building) { return sum + (parseFloat(building.realSurface) || 0); }, 0);
    return returnNumber ? validatedSurface : (0, formatters_1.formatSurface)(validatedSurface);
};
exports.calculateTotalSurface = calculateTotalSurface;
var calculateValidatedBuildings = function (copro) {
    return copro.batiments.filter(function (building) { return building.isValidatedByUser === true; }).length;
};
exports.calculateValidatedBuildings = calculateValidatedBuildings;

"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateOwner = exports.setReloadOwners = exports.setTypeFiltered = exports.setMonthFiltered = exports.setInteraction = exports.setContact = exports.setShowPhone = exports.setCloturerRows = exports.setExpandedRows = exports.updateOwnerOfDpe = exports.fetchDpes = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var CommOwnerService_1 = require("../components/Prospecting/CommOwner/CommOwnerService");
var ToastrNotif_module_1 = require("../components/common/Notification/ToastrNotif.module");
exports.fetchDpes = (0, toolkit_1.createAsyncThunk)('dpeOwner/fetchDpes', function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var response;
    var companyId = _b.companyId, jwt = _b.jwt, monthFiltered = _b.monthFiltered;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4 /*yield*/, (0, CommOwnerService_1.fetchDpesData)(companyId, jwt, monthFiltered)];
            case 1:
                response = _c.sent();
                return [2 /*return*/, response];
        }
    });
}); });
var initialState = {
    data: [],
    status: 'idle',
    error: null,
    expandedRows: null,
    cloturerRows: null,
    showPhone: [],
    monthFiltered: null,
    reloadOwners: false,
};
var dpeOwnerSlice = (0, toolkit_1.createSlice)({
    name: 'dpeOwner',
    initialState: initialState,
    reducers: {
        updateOwnerOfDpe: function (state, action) {
            var _a = action.payload, dpeId = _a[0], updatedOwner = _a[1];
            var dpeToUpdate = state.data.find(function (dpe) { return dpe.id === dpeId; });
            if (dpeToUpdate) {
                var ownerIndex = dpeToUpdate.owners.findIndex(function (owner) { return owner.id === updatedOwner.id; });
                if (ownerIndex >= 0) {
                    dpeToUpdate.owners[ownerIndex] = __assign(__assign({}, dpeToUpdate.owners[ownerIndex]), updatedOwner);
                }
            }
        },
        setExpandedRows: function (state, action) {
            state.expandedRows = action.payload;
        },
        setCloturerRows: function (state, action) {
            state.cloturerRows = action.payload;
        },
        setShowPhone: function (state, action) {
            state.showPhone = action.payload;
        },
        setMonthFiltered: function (state, action) {
            var _a;
            state.monthFiltered = { date: action.payload, type: ((_a = state.monthFiltered) === null || _a === void 0 ? void 0 : _a.type) || 'reception' };
        },
        setTypeFiltered: function (state, action) {
            var _a;
            if (action.payload == 'reception' || action.payload == 'etablissement') {
                state.monthFiltered = { date: (_a = state.monthFiltered) === null || _a === void 0 ? void 0 : _a.date, type: action.payload };
            }
            else {
                throw new Error('Invalid type');
            }
        },
        setReloadOwners: function (state, action) {
            state.reloadOwners = action.payload;
        },
        setContact: function (state, action) {
            // use action.payload.ownerId to add a new contact to the owner at the begginning of the contacts array
            state.data = state.data.map(function (dpe) { return (__assign(__assign({}, dpe), { owners: dpe.owners.map(function (owner) {
                    if (owner.id !== action.payload.ownerId) {
                        return owner;
                    }
                    return __assign(__assign({}, owner), { contacts: __spreadArray([
                            {
                                id: action.payload.contact.id,
                                type: action.payload.contact.type,
                                createdAt: action.payload.contact.createdAt,
                                updatedAt: action.payload.contact.updatedAt,
                            }
                        ], owner.contacts, true) });
                }) })); });
        },
        setInteraction: function (state, action) {
            // use action.payload.ownerId to add a new interaction to remove the owner from the list of owners to treat
            state.data = state.data.map(function (dpe) { return (__assign(__assign({}, dpe), { owners: dpe.owners.filter(function (owner) { return owner.id !== action.payload.ownerId; }) })); });
            // check if the dpe is empty and remove it from the list if no more owners
            state.data = state.data.filter(function (dpe) { return dpe.owners.length > 0; });
        },
        updateOwner: function (state, action) {
            var _a = action.payload, ownerId = _a.ownerId, updatedOwner = _a.updatedOwner;
            state.data = state.data.map(function (dpe) { return (__assign(__assign({}, dpe), { owners: dpe.owners.map(function (owner) {
                    return owner.id === ownerId ? updatedOwner : owner;
                }) })); });
        },
    },
    extraReducers: function (builder) {
        builder
            .addCase(exports.fetchDpes.pending, function (state) {
            state.status = 'loading';
        })
            .addCase(exports.fetchDpes.fulfilled, function (state, action) {
            state.status = 'succeeded';
            state.data = action.payload;
            // reset expandedRows and cloturerRows when fetching new data
            state.expandedRows = null;
            state.cloturerRows = null;
        })
            .addCase(exports.fetchDpes.rejected, function (state, action) {
            state.status = 'failed';
            state.error = action.error.message || null;
            (0, ToastrNotif_module_1.showToast)('error', 'Une erreur est survenue lors de la récupération des données.');
        });
    },
});
exports.updateOwnerOfDpe = (_a = dpeOwnerSlice.actions, _a.updateOwnerOfDpe), exports.setExpandedRows = _a.setExpandedRows, exports.setCloturerRows = _a.setCloturerRows, exports.setShowPhone = _a.setShowPhone, exports.setContact = _a.setContact, exports.setInteraction = _a.setInteraction, exports.setMonthFiltered = _a.setMonthFiltered, exports.setTypeFiltered = _a.setTypeFiltered, exports.setReloadOwners = _a.setReloadOwners, exports.updateOwner = _a.updateOwner;
exports.default = dpeOwnerSlice.reducer;

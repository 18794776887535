"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
function Badge(_a) {
    var _b = _a.type, type = _b === void 0 ? 'solid' : _b, mode = _a.mode, _c = _a.size, size = _c === void 0 ? 'medium' : _c, text = _a.text, onClick = _a.onClick, _d = _a.dismissible, dismissible = _d === void 0 ? false : _d, _e = _a.className, className = _e === void 0 ? '' : _e, _f = _a.textAlign, textAlign = _f === void 0 ? 'center' : _f;
    var _g = (0, react_1.useState)(true), isVisible = _g[0], setIsVisible = _g[1];
    if (!isVisible) {
        return null;
    }
    var baseClass = 'inline-flex justify-between items-center px-5 py-2 rounded-lg font-medium';
    var typeClass = {
        solid: '',
        outline: {
            error: 'border border-red-400',
            warning: 'border border-orange-400',
            success: 'border border-green-400',
        },
    };
    var modeClass = {
        error: 'text-red-800 bg-red-100',
        warning: 'text-orange-800 bg-orange-200',
        success: 'text-green-900 bg-green-100',
    };
    var sizeClass = {
        small: 'text-sm',
        medium: 'text-base',
        large: 'text-lg',
    };
    var textAlignClass = {
        left: 'text-left',
        center: 'text-center',
        right: 'text-right',
    };
    var combinedClass = "".concat(baseClass, " ").concat(type === 'outline' ? typeClass.outline[mode] : '', " ").concat(modeClass[mode], " ").concat(sizeClass[size], " ").concat(textAlignClass[textAlign], " ").concat(onClick ? 'cursor-pointer' : '', " ").concat(className);
    return (react_1.default.createElement("span", { className: combinedClass, onClick: onClick },
        text,
        dismissible && (react_1.default.createElement("button", { className: "ml-2 text-gray-400 hover:text-gray-500 focus:outline-none", onClick: function () { return setIsVisible(false); } }, "\u00D7"))));
}
exports.default = Badge;
